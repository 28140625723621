import "moment/locale/sv";
import "moment/locale/nb";

import * as gqltypes from "../gqltypes";
import { getSettings } from "../settings";
import de from "./locale/de.json";
import en from "./locale/en.json";
import nb from "./locale/nb.json";
import sv from "./locale/sv.json";
import base from "./base.json";
import nn from "./locale/nn.json";

const settings = getSettings();
const isNotProduction =
  settings.releaseStage !== "production" && settings.releaseStage !== "stage";
const isLocalOrDev =
  settings.releaseStage === "local" || settings.releaseStage === "dev";

const languages: { [language: string]: { [key: string]: string } } = {
  en,
  nb,
  sv,
  de,
  base,
  nn,
};

export const languageNames: {
  [language in gqltypes.ISO6391Language]: string;
} = {
  en: "English",
  sv: "Svenska",
  nn: "Nynorsk",
  nb: "Bokmål",
  de: "Deutsch",
  da: "Dansk",
};

// List of available languages for the UI
const avilableUiLanguages = ["sv", "en", "nb"];
if (isNotProduction) {
  avilableUiLanguages.push("de");
  avilableUiLanguages.push("nn");
}
if (isLocalOrDev) {
  avilableUiLanguages.push("translation");
}

// Refers to the base translation language, should be en
const baseLanguage = "en";

export const getAvailableUiLanguages = () => {
  const available: string[] = [...avilableUiLanguages];
  return available;
};

export const getFirstAvailableUiLanguage = (preferredLanguages: string[]) => {
  for (const preferred of preferredLanguages) {
    for (const available of avilableUiLanguages) {
      if (preferred === available) {
        return available;
      }
    }
  }
  return baseLanguage;
};

const translate = (language: string, key: string, ...values: any[]): string => {
  const languageStrings = languages[language] || {};

  const translation =
    languageStrings[key] ||
    (language !== "translation"
      ? (en as typeof languageStrings)[key] ||
        (base as typeof languageStrings)[key] ||
        `#${key}_${values.map((v, i) => `{${i}}`)}#`
      : `#${key}_${values.map((v, i) => `{${i}}`)}#`);
  const re = translation.replace(
    /\{([^{}]+)\}/g,
    ([match, i]) => values[i as any]
  );
  return re;
};

type TrFunc = (key: string, ...values: any[]) => string;

const trCache: { [language: string]: TrFunc | undefined } = {};

export const getTr = (language: string) => {
  if (trCache[language]) return trCache[language]!;

  trCache[language] = (key: string, ...values: any[]) =>
    translate(language, key, ...values);

  return trCache[language]!;
};
