import gql from "graphql-tag";
import * as _ from "lodash";
import * as React from "react";
import { QueryResult } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import {
  AdminContext,
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import { Button, ISTContainer, Loading } from "../../Common";
import { ISTContainerExpandable } from "../../Common/components/ISTContainerExpandable";
import { Tooltip } from "../../Common/components/Tooltip";
import {
  getPartsFromCombinedIdCustomerId,
  skolidIstOrgRegex,
} from "../../Common/utils";
import * as gqltypes from "../../gqltypes";
import {
  formComponentPermissions,
  getComponentPermissionDisplayName,
  getEmploymentRoleName,
  getEmploymentRoleNames,
  getPermissionDescription,
  getPermissionDisplayName,
  permissionOnOrgOnly,
  permissionOrder,
} from "../../permissions";
import { links } from "../links";
import FormSearchSelectRounded from "../../Common/components/FormSearchSelectRounded";
import { FormInputRounded } from "../../Common/components/FormInputRounded";

interface SchoolUnitPermission {
  employmentRoles: gqltypes.EmploymentRole[];
  displayName: string | null;
  userId: string | null;
  user?: gqltypes.OrganisationPermissions_organisation_permissions_organisation_user | null;
  client: gqltypes.OrganisationPermissions_organisation_permissions_schoolUnits_permissions_client | null;
  roles: gqltypes.OrganisationPermissions_organisation_permissions_schoolUnits_permissions_roles[];
}

interface SchoolUnitPermissionConditional {
  employmentRoles?: gqltypes.EmploymentRole[];
  displayName?: string | null;
  userId?: string | null;
  user?: gqltypes.OrganisationPermissions_organisation_permissions_organisation_user | null;
  client: gqltypes.OrganisationPermissions_organisation_permissions_schoolUnits_permissions_client | null;
  roles: gqltypes.OrganisationPermissions_organisation_permissions_schoolUnits_permissions_roles[];
  __typename?: string;
}

interface Props extends Response, AdminContext, Translation {}

interface State {
  userSearchText: string;
  debouncedUserSearchText: string;
  schoolUnitNameFilter: string;
  userNameFilter: string;
  employmentRoleFilter: { value: string; label: string }[];
  expandedSchoolUnits: { [id: string]: boolean };
  expandAll: boolean;
}

// Memoize results from getAllEmploymentRoleOptions
let lastTr: any = null;
let lastRes: { value: string; label: string }[] | null = null;

const getAllEmploymentRoleOptions = (tr: Translation["tr"]) => {
  if (lastRes && lastTr === tr) return lastRes;

  lastTr = tr;
  lastRes = _.orderBy(
    Object.keys(gqltypes.EmploymentRole).map((role) => ({
      value: role,
      label: getEmploymentRoleName(tr, role as gqltypes.EmploymentRole),
    })),
    "label"
  );
  lastRes.push({
    value: "without_employment",
    label: tr("permissionsEmploymentTypeWithoutEmployment"),
  });

  return lastRes;
};

const debouncedSearch = _.debounce((func) => func(), 500);

const SEARCH_QUERY = gql`
  query FindUserQuery($key: String!) {
    findUsers(key: $key) {
      id
      source
      customerId
      name
      idp
      skolidOrganization {
        id
        name
        country
      }
    }
  }
`;

const GET_PERMISSION_USERS_QUERY = gql`
  query GetPermissionUsersQuery(
    $educloudUserIds: [String!]
    $skolidUserIds: [String!]
    $customerId: String!
    $context: Context!
  ) {
    permissionUsers(
      educloudUserIds: $educloudUserIds
      skolidUserIds: $skolidUserIds
      customerId: $customerId
      context: $context
    ) {
      educloud {
        userId
        source
        user {
          connectedUsers {
            id
            source
          }
          id
          source
          customerId
          name
          idp
          skolidOrganization {
            id
            name
            country
          }
        }
      }
      skolid {
        userId
        source
        user {
          connectedUsers {
            id
            source
          }
          id
          source
          customerId
          name
          idp
          skolidOrganization {
            id
            name
            country
          }
        }
      }
    }
  }
`;

const LinkToUserPermission = (props: {
  tr: Translation["tr"];
  user: Pick<gqltypes.User, "id" | "name" | "source" | "idp"> & {
    customerId: string | null;
    skolidOrganization: { id: string; name: string } | null;
  };
  organisationSkolidOrgId: string | null;
  path: string;
  maxLength?: number;
}) => {
  const elId =
    "path_" + props.path.replace(/[\.:]/g, "") + "___" + props.user.id;
  const name =
    props.user.name || props.tr("permissionSearchSkolidUserMissingName");
  return (
    <Link to={links.admin.permissions.user(props.user)}>
      <span id={elId}>{name}</span>
      <UncontrolledTooltip target={elId}>{name}</UncontrolledTooltip>
    </Link>
  );
};

const LinkToUserPermissionWithRoles = (props: {
  tr: Translation["tr"];
  user: Pick<gqltypes.User, "id" | "name" | "source" | "idp"> & {
    customerId: string | null;
    skolidOrganization: { id: string; name: string } | null;
  };
  organisationSkolidOrgId: string | null;
  path: string;
  maxLength?: number;
  roles?: string;
}) => {
  const elId =
    "path_" + props.path.replace(/[\.:]/g, "") + "___" + props.user.id;
  return (
    <div
      className="d-flex justify-content-start align-items-center"
      style={{
        minWidth: "250px",
      }}
      id={elId}
    >
      <span
        className="text-truncate"
        style={{
          flex: "0 2 auto",
        }}
      >
        <Link to={links.admin.permissions.user(props.user)}>
          <span>{props.user.name}</span>
        </Link>
      </span>
      {props.roles ? (
        <span
          className="text-truncate ml-1"
          style={{
            color: "var(--gray-dark)",
            fontSize: "12px",
            flex: "1 0 20%",
          }}
        >
          {props.roles}
        </span>
      ) : null}
      <UncontrolledTooltip target={elId}>
        {props.user.name + " " + (props.roles ? props.roles : "")}
      </UncontrolledTooltip>
    </div>
  );
};

const UserSkolidOrganisation = (props: {
  user: Pick<gqltypes.User, "id" | "name" | "source" | "idp"> & {
    customerId: string | null;
    skolidOrganization: { id: string; name: string } | null;
  };
  tr: Translation["tr"];
  skolidOrgId: string | null | undefined;
  warningIconSuffix?: boolean;
}) => {
  let isSkolidOrgAccount;
  let isSkolidIstAccount;
  if (props.user) {
    isSkolidOrgAccount = props.skolidOrgId
      ? (props.skolidOrgId || null) === props.user.skolidOrganization?.id
      : false;
    isSkolidIstAccount = props.user.skolidOrganization?.id
      ? skolidIstOrgRegex.test(props.user.skolidOrganization?.id)
      : false;
  }
  return (
    <>
      <span
        id={"tooltip_org_skolId" + props.user.id}
        data-toggle="tooltip"
        style={{ fontSize: "12px", maxWidth: "90px", minWidth: "90px" }}
        className="text-truncate"
      >
        {(isSkolidOrgAccount || isSkolidIstAccount) &&
        props.user?.skolidOrganization?.id ? (
          <>{props.user?.skolidOrganization?.id.toUpperCase()}</>
        ) : null}

        {!isSkolidOrgAccount &&
        !isSkolidIstAccount &&
        props.user?.skolidOrganization?.id &&
        !props.warningIconSuffix ? (
          <>
            <i
              className="fas fa-exclamation-triangle"
              style={{
                color: "var(--yellow)",
              }}
            />{" "}
            {props.user?.skolidOrganization?.id.toUpperCase()}
          </>
        ) : null}

        {!isSkolidOrgAccount &&
        !isSkolidIstAccount &&
        props.user?.skolidOrganization?.id &&
        props.warningIconSuffix ? (
          <span className="d-flex align-items-center">
            <span className="text-truncate" style={{ maxWidth: "200px" }}>
              {props.user?.skolidOrganization?.id.toUpperCase()}
            </span>
            <span className="d-flex">
              <i
                className="fas fa-exclamation-triangle ml-1"
                style={{
                  color: "var(--yellow)",
                }}
              />
            </span>
          </span>
        ) : null}
      </span>
      <UncontrolledTooltip target={"tooltip_org_skolId" + props.user.id}>
        {props.user?.skolidOrganization?.name
          ? props.user?.skolidOrganization?.name
          : ""}
        {!isSkolidOrgAccount &&
        !isSkolidIstAccount &&
        props.user?.skolidOrganization?.id
          ? " - " + props.tr("permissionToolTipOtherSkolidOrganisation")
          : null}
      </UncontrolledTooltip>
    </>
  );
};

const AggregatedReadView = (props: {
  tr: Translation["tr"];
  roles: { roleId: string }[];
  borderStyle: string;
}) => {
  return (
    <td
      className="text-center align-middle"
      style={{
        borderBottom: `1px ${props.borderStyle} var(--secondary)`,
        borderLeft: "1px dashed var(--secondary)",
        background: "var(--white)",
        minWidth: "230px",
      }}
    >
      <div
        className="d-flex justify-content-center flex-wrap"
        style={{ gap: "6px" }}
      >
        {formComponentPermissions.map((role) => (
          <span
            key={role}
            className={`badge badge-${
              props.roles.some((r) => r.roleId === (role as any))
                ? "success"
                : "light text-muted"
            }`}
            style={{ fontSize: "10px" }}
          >
            {getComponentPermissionDisplayName(role, props.tr)}
          </span>
        ))}
      </div>
    </td>
  );
};

const LinkToClientPermission = (props: {
  client: Pick<gqltypes.Client, "clientId" | "name">;
  path: string;
}) => {
  const elId =
    "path_" +
    props.path.replace(/[\.:]/g, "") +
    "___" +
    props.client.clientId.replace(/[\.:]/g, "");
  return (
    <Link to={links.admin.permissions.client(props.client.clientId)}>
      <span id={elId}>{props.client.name}</span>
      <UncontrolledTooltip target={elId}>
        {props.client.name}
      </UncontrolledTooltip>
    </Link>
  );
};

const permissionColStyle = {
  td: {
    borderLeft: "1px dashed var(--secondary)",
    borderBottom: "1px solid var(--secondary)",
    background: "var(--white)",
    verticalAlign: "middle",
  },
  tdh: {
    borderLeft: "1px dashed var(--secondary)",
    borderBottom: "1px solid var(--secondary)",
    background: "var(--white)",
    width: "350px",
    verticalAlign: "bottom",
  },
};

class Permissions extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      userSearchText: "",
      debouncedUserSearchText: "",
      schoolUnitNameFilter: "",
      userNameFilter: "",
      employmentRoleFilter: [],
      expandedSchoolUnits: {},
      expandAll: false,
    };
  }

  public render() {
    const { tr } = this.props;

    const variableOrgId = this.props.data.variables?.context?.org;

    if (
      (!this.props.data.organisation && this.props.data.loading) ||
      (this.props.data.organisation &&
        variableOrgId !== this.props.data.organisation?.id)
    ) {
      return (
        <main className="top content">
          <Loading />
        </main>
      );
    } else if (!this.props.data.organisation) {
      return (
        <main className="top content">
          <h1>{tr("failedToFetchData")}</h1>
        </main>
      );
    }

    const suNameFilter = new RegExp(
      _.escapeRegExp(this.state.schoolUnitNameFilter),
      "i"
    );

    const personNameFilter = new RegExp(
      _.escapeRegExp(this.state.userNameFilter),
      "i"
    );

    const selectedEmploymentRoles = this.state.employmentRoleFilter.map(
      (role) => role.value
    );

    const schoolUnits = _.orderBy(
      this.props.data.organisation.permissions.schoolUnits
        .filter((su) => suNameFilter.test(su.displayName))
        .map((su) => {
          const educloudUserIds: string[] = [];
          const skolidUserIds: string[] = [];
          su.permissions.forEach((permission) => {
            if (permission.userId && permission.source) {
              switch (permission.source) {
                case gqltypes.UserSource.educloud:
                  educloudUserIds.push(permission.userId);
                  break;
                case gqltypes.UserSource.skolid:
                  skolidUserIds.push(permission.userId);
                  break;
              }
            }
          });

          return {
            ...su,
            permissions: _.orderBy(
              su.permissions.filter((perm) =>
                personNameFilter.test(perm.displayName ? perm.displayName : "")
              ),
              "displayName"
            ),
            educloudUserIds,
            skolidUserIds,
          };
        })
        .filter((su) => su.permissions.length),
      "displayName"
    );

    const sortedOrganisationByName = _.orderBy(
      this.props.data.organisation.permissions.organisation,
      "user.name",
      "asc"
    ) as gqltypes.OrganisationPermissions_organisation_permissions_organisation[];

    const sortedArray = sortListByConnectedUsers(
      sortedOrganisationByName
    ).sorted;

    return (
      <main>
        <h1>{tr("permissionTitle")}</h1>
        <p className="col-12 col-md-9 p-0 pb-3">
          {tr("permissionDescription")}
        </p>
        <div className="box">
          <div className="box-header">{tr("permissionFindUserTitle")}</div>
          <div className="row p-content">
            <p className="col-12 col-md-9 pb-3">
              {tr("permissionFindUserDescription")}
            </p>
            <div className="col-md-6">
              <FormInputRounded
                id="searchField"
                label={tr("personSearchLookupLabel")}
                placeholder={tr("personSearchLookupPlaceholder")}
                onChange={(event) => {
                  this.setState({ userSearchText: event.currentTarget.value });
                  debouncedSearch(() =>
                    this.setState((state) => ({
                      debouncedUserSearchText: state.userSearchText,
                    }))
                  );
                }}
                value={this.state.userSearchText}
              />
            </div>
            <div className="col-12">
              <Query<gqltypes.FindUserQuery, gqltypes.FindUserQueryVariables>
                query={SEARCH_QUERY}
                variables={{ key: this.state.debouncedUserSearchText }}
                skip={!this.state.debouncedUserSearchText}
              >
                {({ loading, error, data }) => {
                  if (!this.state.debouncedUserSearchText) {
                    return null;
                  }
                  if (loading) {
                    return <Loading />;
                  }
                  if (error || !data) {
                    return (
                      <div className="alert alert-warning m-content">
                        {tr("failedToFetchData")}
                      </div>
                    );
                  }

                  if (data.findUsers.length === 0) {
                    return (
                      <div className="alert alert-info">
                        {tr("noSearchHits")}
                        {/\s/.test(this.state.debouncedUserSearchText) ? (
                          <div>{tr("noSearchHitsNameNotAllowed")}</div>
                        ) : null}
                      </div>
                    );
                  }
                  return (
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex d-sm-inline-flex flex-column">
                          {data.findUsers.map((user) => (
                            <div
                              key={user.id}
                              className="d-block align-items-center flex-wrap d-sm-flex flex-sm-row flex-column"
                            >
                              <div
                                className="text-truncate"
                                style={{
                                  flex: "1.5 1 0",
                                }}
                              >
                                <span>
                                  <LinkToUserPermission
                                    tr={tr}
                                    user={user}
                                    path="search"
                                    organisationSkolidOrgId={
                                      this.props.data.organisation
                                        ?.skolidOrgId || null
                                    }
                                  />
                                </span>
                              </div>
                              <div
                                className="ml-1 d-flex align-items-center text-truncate"
                                style={{
                                  flex: "1.4 1 0",
                                  minWidth: "200px",
                                  fontSize: "12px",
                                  color: "var(--gray)",
                                }}
                              >
                                <span className="mr-1">{`- ${tr(
                                  "permissionSearchSkolidAccountPrefix"
                                )}: `}</span>
                                <UserSkolidOrganisation
                                  skolidOrgId={
                                    this.props.data.organisation?.skolidOrgId
                                  }
                                  user={user}
                                  tr={tr}
                                  warningIconSuffix={true}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Query>
            </div>
          </div>
        </div>
        <ISTContainerExpandable
          header={this.props.data.organisation.displayName}
          description={tr("permissonPeopleWithSystemLevelRights")}
        >
          <div style={{ overflowX: "auto", maxHeight: "80vh" }}>
            <table
              className={`table ${
                this.props.data.organisation.permissions.organisation.length >=
                10
                  ? "table-sticky-header"
                  : ""
              } mb-1 mt-0 table-last-row`}
              style={{ minWidth: "1080px" }}
            >
              <thead>
                <tr>
                  <th
                    className="no-upper"
                    scope="col"
                    style={{
                      borderBottom: "1px solid var(--secondary)",
                      width: "240px",
                      verticalAlign: "bottom",
                    }}
                  >
                    {tr("permissionColumnName")}
                  </th>

                  <th
                    className="text-right no-upper"
                    scope="col"
                    style={{
                      borderBottom: "1px solid var(--secondary)",
                      verticalAlign: "bottom",
                    }}
                  >
                    <span>{tr("permissionColumnSkolID")}</span>
                  </th>

                  {permissionOrder.map((perm) => {
                    perm === gqltypes.PermissionType.read;
                    return (
                      <th
                        scope="col"
                        key={`org_${perm}`}
                        style={permissionColStyle.tdh}
                        className="text-center no-upper"
                      >
                        <Tooltip
                          target={"tooltip_org_" + perm}
                          content={getPermissionDisplayName(perm, tr)}
                          message={getPermissionDescription(perm, tr)}
                        />
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {!this.props.data.organisation.permissions.organisation
                  .length ? (
                  <div className="px-content">{tr("tableNoRows")}</div>
                ) : null}
                {sortedArray.map((subjectPerm, i) => {
                  let checkIfSame = "solid";
                  subjectPerm.user?.connectedUsers?.forEach((e) => {
                    if (
                      sortedArray[i + 1] &&
                      sortedArray[i + 1].user?.id === e.id
                    )
                      checkIfSame = "dashed";
                  });

                  return subjectPerm.user ? (
                    <tr key={`org_${subjectPerm.user.id}`}>
                      <td
                        style={{
                          borderBottom: `1px ${checkIfSame} var(--secondary)`,
                          maxWidth: "270px",
                          fontSize: "14px",
                          verticalAlign: "middle",
                        }}
                      >
                        <span
                          className="d-block text-truncate"
                          style={{
                            minWidth: "250px",
                          }}
                        >
                          <LinkToUserPermission
                            tr={tr}
                            user={subjectPerm.user}
                            path="org"
                            organisationSkolidOrgId={
                              this.props.data.organisation?.skolidOrgId || null
                            }
                          />
                        </span>
                      </td>
                      <td
                        style={{
                          borderBottom: `1px ${checkIfSame} var(--secondary)`,
                          color: "var(--gray)",
                          whiteSpace: "nowrap",
                          maxWidth: "130px",
                          verticalAlign: "middle",
                        }}
                        className="text-right"
                        key={"org_skolId" + subjectPerm.user.id}
                      >
                        <div className="d-inline-flex justify-content-end ">
                          <UserSkolidOrganisation
                            skolidOrgId={
                              this.props.data.organisation?.skolidOrgId
                            }
                            user={subjectPerm.user}
                            tr={tr}
                          />
                        </div>
                      </td>

                      {permissionOrder.map((perm) =>
                        perm === gqltypes.PermissionType.read ? (
                          <AggregatedReadView
                            key={`org_${subjectPerm.user!.id}_${perm}`}
                            tr={tr}
                            roles={subjectPerm.roles}
                            borderStyle={checkIfSame}
                          />
                        ) : (
                          <td
                            key={`org_${subjectPerm.user!.id}_${perm}`}
                            className="text-center"
                            style={{
                              borderBottom: `1px ${checkIfSame} var(--secondary)`,
                              borderLeft: "1px dashed var(--secondary)",
                              background: "var(--white)",
                              verticalAlign: "middle",
                            }}
                          >
                            {subjectPerm.roles.some(
                              (role) => role.roleId === perm
                            ) ? (
                              <i className="fa fa-check text-dark" />
                            ) : (
                              ""
                            )}
                          </td>
                        )
                      )}
                    </tr>
                  ) : subjectPerm.client ? (
                    <tr key={subjectPerm.client.id}>
                      <td
                        style={{
                          borderBottom: `1px ${checkIfSame} var(--secondary)`,
                          fontSize: "14px",
                          verticalAlign: "middle",
                        }}
                      >
                        <LinkToClientPermission
                          client={subjectPerm.client}
                          path="org"
                        />
                      </td>
                      <td
                        style={{
                          borderBottom: `1px ${checkIfSame} var(--secondary)`,
                          color: "var(--gray)",
                          verticalAlign: "middle",
                        }}
                        className="text-right td-color"
                      >
                        <span
                          id={
                            "tooltip_org_skolId" +
                            subjectPerm.client.id.replace(/\./g, "_")
                          }
                          style={{ fontSize: "12px" }}
                        >
                          API
                          <UncontrolledTooltip
                            target={
                              "tooltip_org_skolId" +
                              subjectPerm.client.id.replace(/\./g, "_")
                            }
                          >
                            API
                          </UncontrolledTooltip>
                        </span>
                      </td>
                      {permissionOrder.map((perm) =>
                        perm === gqltypes.PermissionType.read ? (
                          <AggregatedReadView
                            key={`org_${subjectPerm.client!.id}_${perm}`}
                            tr={tr}
                            roles={subjectPerm.roles}
                            borderStyle={checkIfSame}
                          />
                        ) : (
                          <td
                            key={`org_${subjectPerm.client!.id}_${perm}`}
                            className="text-center"
                            style={permissionColStyle.td}
                          >
                            {subjectPerm.roles.some(
                              (role) => role.roleId === perm
                            ) ? (
                              <i className="fa fa-check text-dark" />
                            ) : (
                              ""
                            )}
                          </td>
                        )
                      )}
                    </tr>
                  ) : null;
                })}
              </tbody>
            </table>
          </div>
        </ISTContainerExpandable>

        <ISTContainer header={tr("schoolUnits")}>
          <div className="p-content  row d-flex align-items-end">
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <h3>{tr("permissionFilter")}</h3>
                </div>
                <div className="col-6">
                  <div className="form-group d-flex justify-content-end">
                    <Button
                      className="button-filter rounded-pill"
                      label={tr("clearFilter")}
                      level="secondary"
                      onClick={() =>
                        this.setState({
                          schoolUnitNameFilter: "",
                          userNameFilter: "",
                          employmentRoleFilter: [],
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <FormInputRounded
                    label={tr("permissionFilterOnSchoolUnitName")}
                    id="schoolUnitNameFilter"
                    type="search"
                    value={this.state.schoolUnitNameFilter}
                    placeholder={tr(
                      "permissionFilterOnSchoolUnitNamePlaceholder"
                    )}
                    onChange={(event) =>
                      this.setState({
                        schoolUnitNameFilter: event.currentTarget.value,
                      })
                    }
                  />
                </div>
                <div className="col-md-4">
                  <FormInputRounded
                    label={tr("permissionFilterOnPersonName")}
                    id="userNameFilter"
                    type="search"
                    value={this.state.userNameFilter}
                    placeholder={tr("permissionFilterOnPersonNamePlaceholder")}
                    onChange={(event) =>
                      this.setState({
                        userNameFilter: event.currentTarget.value,
                      })
                    }
                  />
                </div>
                <div className="col-md-4">
                  <FormSearchSelectRounded
                    className="form-group"
                    label={tr("permissionEmploymentRoleFilterPlaceholder")}
                    placeholder={tr(
                      "permissionEmploymentRoleFilterPlaceholder"
                    )}
                    isMulti
                    isSearchable={false}
                    closeMenuOnSelect={false}
                    value={this.state.employmentRoleFilter}
                    onChange={(selected: any) => {
                      this.setState({ employmentRoleFilter: selected ?? [] });
                    }}
                    options={getAllEmploymentRoleOptions(tr)}
                  />
                </div>
              </div>
            </div>
          </div>
          {schoolUnits.map((suPerm, i) => {
            const expanded =
              this.state.expandAll ||
              this.state.expandedSchoolUnits[suPerm.resourceId];
            const { customerId } = getPartsFromCombinedIdCustomerId(
              suPerm.resourceId
            );

            //Filter function to show/hide schoolunits with accounts with the selected employment role.
            let filteredSchoolUnitsWithEmploymentRoles: string[] = [];
            if (selectedEmploymentRoles.length !== 0) {
              suPerm.permissions.forEach((su) => {
                if (su.employmentRoles.length > 0) {
                  filteredSchoolUnitsWithEmploymentRoles =
                    filteredSchoolUnitsWithEmploymentRoles.concat(
                      su.employmentRoles
                    );
                }
              });
              filteredSchoolUnitsWithEmploymentRoles = _.intersection(
                filteredSchoolUnitsWithEmploymentRoles,
                selectedEmploymentRoles
              );
            }
            if (
              selectedEmploymentRoles.length === 0 ||
              filteredSchoolUnitsWithEmploymentRoles.length > 0
            )
              return (
                <div
                  key={suPerm.resourceId}
                  style={
                    this.state.expandedSchoolUnits[suPerm.resourceId]
                      ? {
                          background: "var(--blue-xx-light)",
                          borderTop: "1px solid var(--primary)",
                        }
                      : {
                          background: "var(--light)",
                          borderTop: "1px solid var(--primary)",
                        }
                  }
                >
                  <div
                    className="d-flex align-items-center clickable"
                    style={{ minHeight: "45px" }}
                    onClick={() => {
                      this.toggleSchoolUnitExpanded(suPerm.resourceId);
                    }}
                  >
                    <h4
                      style={
                        this.state.expandedSchoolUnits[suPerm.resourceId]
                          ? { fontWeight: "bold" }
                          : { fontWeight: "normal" }
                      }
                      className="px-content pt-2 pb-2 clickable m-0"
                    >
                      {suPerm.displayName}
                      {suPerm.schoolUnit.organisationCode
                        ? ` (${suPerm.schoolUnit.organisationCode})`
                        : ""}{" "}
                      <i
                        className={`fas fa-caret-${expanded ? "up" : "down"}`}
                      />
                    </h4>
                  </div>

                  {expanded && (
                    <Query<
                      gqltypes.GetPermissionUsersQuery,
                      gqltypes.GetPermissionUsersQueryVariables
                    >
                      query={GET_PERMISSION_USERS_QUERY}
                      variables={{
                        educloudUserIds: suPerm.educloudUserIds,
                        skolidUserIds: suPerm.skolidUserIds,
                        customerId: customerId,
                        context: this.props.adminContext,
                      }}
                      skip={!suPerm.schoolUnit.id}
                    >
                      {({ loading, error, data }) => {
                        if (loading) {
                          return <Loading className="w-100" />;
                        }
                        if (error) {
                          return (
                            <div className="alert alert-warning m-content">
                              {tr("failedToFetchData")}
                            </div>
                          );
                        }

                        const userIdToPermissionMap: Map<
                          string,
                          gqltypes.OrganisationPermissions_organisation_permissions_schoolUnits_permissions
                        > = new Map();
                        const clientsPermissions: gqltypes.OrganisationPermissions_organisation_permissions_schoolUnits_permissions[] =
                          [];
                        const processedSkolidPermissions: string[] = [];
                        let mergedSchoolUnitPermissions: SchoolUnitPermission[] =
                          [];
                        suPerm.permissions.reduce((previous, current) => {
                          if (current.client) {
                            clientsPermissions.push(current);
                          }
                          return current.userId
                            ? previous.set(current.userId, current)
                            : userIdToPermissionMap;
                        }, userIdToPermissionMap);

                        data?.permissionUsers.educloud
                          .filter((user) =>
                            personNameFilter.test(
                              user.user.name ? user.user.name : ""
                            )
                          )
                          .forEach((user) => {
                            const mergedPermission: SchoolUnitPermission = {
                              client: null,
                              displayName: user.user.name,
                              employmentRoles: [],
                              roles: [],
                              user: user.user as gqltypes.OrganisationPermissions_organisation_permissions_organisation_user,
                              userId: user.user.id,
                            };
                            if (
                              user.user.source === gqltypes.UserSource.skolid
                            ) {
                              processedSkolidPermissions.push(user.user.id);
                              const permissions = [
                                userIdToPermissionMap.get(user.userId),
                                userIdToPermissionMap.get(user.user.id),
                              ];
                              permissions.forEach((permission) => {
                                if (permission) {
                                  mergedPermission.employmentRoles =
                                    mergedPermission.employmentRoles.concat(
                                      permission.employmentRoles
                                    );
                                  mergedPermission.roles =
                                    mergedPermission.roles.concat(
                                      permission.roles
                                    );
                                }
                              });
                            } else {
                              const permission = userIdToPermissionMap.get(
                                user.user.id
                              );
                              if (permission) {
                                mergedPermission.employmentRoles =
                                  mergedPermission.employmentRoles.concat(
                                    permission.employmentRoles
                                  );
                                mergedPermission.roles =
                                  mergedPermission.roles.concat(
                                    permission.roles
                                  );
                              }
                            }
                            mergedSchoolUnitPermissions.push(mergedPermission);
                          });
                        data?.permissionUsers.skolid
                          .filter((user) =>
                            personNameFilter.test(
                              user.user.name ? user.user.name : ""
                            )
                          )
                          .forEach((user) => {
                            if (
                              processedSkolidPermissions.includes(user.user.id)
                            )
                              return;
                            const mergedPermission: SchoolUnitPermission = {
                              client: null,
                              displayName: user.user.name,
                              employmentRoles: [],
                              roles: [],
                              user: user.user as gqltypes.OrganisationPermissions_organisation_permissions_organisation_user,
                              userId: user.user.id,
                            };
                            processedSkolidPermissions.push(user.user.id);
                            const permissions = [
                              userIdToPermissionMap.get(user.userId),
                              userIdToPermissionMap.get(user.user.id),
                            ];
                            permissions.forEach((permission) => {
                              if (permission) {
                                mergedPermission.employmentRoles =
                                  mergedPermission.employmentRoles.concat(
                                    permission.employmentRoles
                                  );
                                mergedPermission.roles =
                                  mergedPermission.roles.concat(
                                    permission.roles
                                  );
                              }
                            });
                            mergedSchoolUnitPermissions.push(mergedPermission);
                          });

                        mergedSchoolUnitPermissions.sort((a, b) =>
                          a.displayName &&
                          b.displayName &&
                          a.displayName > b.displayName
                            ? 1
                            : -1
                        );
                        mergedSchoolUnitPermissions =
                          mergedSchoolUnitPermissions.concat(
                            clientsPermissions
                          );
                        const mergedSchoolUnitPermissionsSorted =
                          sortListByConnectedUsers(
                            mergedSchoolUnitPermissions
                          ).sorted;
                        return (
                          <>
                            <div>
                              {suPerm.schoolUnit.organisationCode ||
                              suPerm.schoolUnit.schoolUnitCode ? (
                                <h3
                                  className="px-content"
                                  style={{ fontSize: "12px" }}
                                >
                                  {suPerm.schoolUnit.organisationCode ? (
                                    <div>
                                      {tr("organisationCode")}:{" "}
                                      <strong>
                                        {suPerm.schoolUnit.organisationCode}
                                      </strong>
                                    </div>
                                  ) : null}
                                  {suPerm.schoolUnit.schoolUnitCode ? (
                                    <div>
                                      {tr("schoolUnitCode")}:{" "}
                                      <strong>
                                        {suPerm.schoolUnit.schoolUnitCode}
                                      </strong>
                                    </div>
                                  ) : null}
                                </h3>
                              ) : (
                                ""
                              )}
                            </div>

                            <div
                              style={{
                                overflowX: "auto",
                                maxHeight:
                                  suPerm.schoolUnit.organisationCode ||
                                  suPerm.schoolUnit.schoolUnitCode
                                    ? "75vh"
                                    : "80vh",
                                width: "100%",
                              }}
                            >
                              <div>
                                <table
                                  className={`table ${
                                    suPerm.permissions.length >= 10
                                      ? "table-sticky-header"
                                      : ""
                                  } table-no-end-line mt-0`}
                                  style={{
                                    marginBottom: "0",
                                    minWidth: "1070px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="no-upper"
                                        style={{
                                          borderBottom:
                                            "solid 1px var(--secondary)",
                                          background: "var(--white)",
                                          verticalAlign: "bottom",
                                        }}
                                      >
                                        {tr("permissionColumnName")}
                                      </th>
                                      <th
                                        className="text-right no-upper"
                                        scope="col"
                                        style={{
                                          borderBottom:
                                            "1px solid var(--secondary)",
                                          background: "var(--white)",
                                          verticalAlign: "bottom",
                                        }}
                                      >
                                        <span>
                                          {tr("permissionColumnSkolID")}
                                        </span>
                                      </th>
                                      {permissionOrder
                                        .filter(
                                          (po) => !permissionOnOrgOnly[po]
                                        )
                                        .map((perm) => (
                                          <th
                                            key={`${suPerm.resourceId}_${perm}`}
                                            scope="col"
                                            style={permissionColStyle.tdh}
                                            className="text-center no-upper"
                                          >
                                            <Tooltip
                                              target={`tooltip_${i}_${perm}`}
                                              content={getPermissionDisplayName(
                                                perm,
                                                tr
                                              )}
                                              message={getPermissionDescription(
                                                perm,
                                                tr
                                              )}
                                            />
                                          </th>
                                        ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {mergedSchoolUnitPermissionsSorted.map(
                                      (subjectPerm, j) => {
                                        let checkIfSame = "solid";
                                        subjectPerm?.user?.connectedUsers?.forEach(
                                          (e) => {
                                            if (
                                              mergedSchoolUnitPermissionsSorted.length >=
                                              j + 2
                                            ) {
                                              if (
                                                mergedSchoolUnitPermissionsSorted[
                                                  j + 1
                                                ].user?.id === e.id
                                              )
                                                checkIfSame = "dashed";
                                            }
                                          }
                                        );
                                        //Filter to show/hide users depending on selected emplotment roles.
                                        const filteredEmploymentRoles =
                                          _.intersection(
                                            subjectPerm.employmentRoles,
                                            selectedEmploymentRoles
                                          );

                                        if (
                                          selectedEmploymentRoles.length ===
                                            0 ||
                                          filteredEmploymentRoles.length > 0
                                        ) {
                                          const roleNames =
                                            getEmploymentRoleNames(
                                              tr,
                                              subjectPerm.employmentRoles!
                                            );
                                          return subjectPerm.user ? (
                                            <tr
                                              key={`org_${subjectPerm.userId}_${subjectPerm.user.id}`}
                                            >
                                              <td
                                                className="text-truncate"
                                                style={{
                                                  borderBottom: `${checkIfSame} 1px var(--secondary)`,
                                                  background: "var(--white)",
                                                  maxWidth: "270px",
                                                  fontSize: "14px",
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                {roleNames ? (
                                                  <LinkToUserPermissionWithRoles
                                                    tr={tr}
                                                    user={subjectPerm.user}
                                                    path={suPerm.resourceId}
                                                    organisationSkolidOrgId={
                                                      this.props.data
                                                        .organisation
                                                        ?.skolidOrgId || null
                                                    }
                                                    roles={roleNames}
                                                  />
                                                ) : (
                                                  <span
                                                    className="d-block text-truncate"
                                                    style={{
                                                      minWidth: "250px",
                                                    }}
                                                  >
                                                    <LinkToUserPermission
                                                      tr={tr}
                                                      user={subjectPerm.user}
                                                      path={suPerm.resourceId}
                                                      organisationSkolidOrgId={
                                                        this.props.data
                                                          .organisation
                                                          ?.skolidOrgId || null
                                                      }
                                                    />
                                                  </span>
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  borderBottom: `${checkIfSame} 1px var(--secondary)`,
                                                  color: "var(--gray)",
                                                  whiteSpace: "nowrap",
                                                  maxWidth: "130px",
                                                  verticalAlign: "middle",
                                                }}
                                                className="text-right td-color"
                                                key={
                                                  "org_skolId" +
                                                  subjectPerm.user.id
                                                }
                                              >
                                                <div className="d-inline-flex justify-content-end">
                                                  <UserSkolidOrganisation
                                                    skolidOrgId={
                                                      this.props.data
                                                        .organisation
                                                        ?.skolidOrgId
                                                    }
                                                    user={subjectPerm.user}
                                                    tr={tr}
                                                  />
                                                </div>
                                              </td>
                                              {permissionOrder
                                                .filter(
                                                  (po) =>
                                                    !permissionOnOrgOnly[po]
                                                )
                                                .map((perm) =>
                                                  perm ===
                                                  gqltypes.PermissionType
                                                    .read ? (
                                                    <AggregatedReadView
                                                      key={`${
                                                        subjectPerm.user!.id
                                                      }_${
                                                        suPerm.resourceId
                                                      }_${perm}`}
                                                      tr={tr}
                                                      roles={subjectPerm.roles}
                                                      borderStyle={checkIfSame}
                                                    />
                                                  ) : (
                                                    <td
                                                      key={`${
                                                        subjectPerm.user!.id
                                                      }_${
                                                        suPerm.resourceId
                                                      }_${perm}`}
                                                      className="text-center td-color"
                                                      style={{
                                                        borderBottom: `${checkIfSame} 1px var(--secondary)`,
                                                        borderLeft:
                                                          "1px dashed var(--secondary)",
                                                        verticalAlign: "middle",
                                                      }}
                                                    >
                                                      {subjectPerm.roles.some(
                                                        (role) =>
                                                          role.roleId === perm
                                                      ) ? (
                                                        <i className="fa fa-check text-dark" />
                                                      ) : (
                                                        ""
                                                      )}
                                                    </td>
                                                  )
                                                )}
                                            </tr>
                                          ) : subjectPerm.client ? (
                                            <tr key={subjectPerm.client.id}>
                                              <td
                                                className="td-color"
                                                style={{
                                                  borderBottom: `${checkIfSame} 1px var(--secondary)`,
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                <LinkToClientPermission
                                                  client={subjectPerm.client}
                                                  path={suPerm.resourceId}
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  borderBottom:
                                                    "solid 1px var(--secondary)",
                                                  color: "var(--gray)",
                                                  verticalAlign: "middle",
                                                }}
                                                className="text-right td-color"
                                              >
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                  id={
                                                    "tooltip_" +
                                                    suPerm.resourceId.replace(
                                                      /[.:]/g,
                                                      "_"
                                                    ) +
                                                    "_skolId" +
                                                    subjectPerm.client.id.replace(
                                                      /[.:]/g,
                                                      "_"
                                                    )
                                                  }
                                                >
                                                  API
                                                  <UncontrolledTooltip
                                                    target={
                                                      "tooltip_" +
                                                      suPerm.resourceId.replace(
                                                        /[.:]/g,
                                                        "_"
                                                      ) +
                                                      "_skolId" +
                                                      subjectPerm.client.id.replace(
                                                        /[.:]/g,
                                                        "_"
                                                      )
                                                    }
                                                  >
                                                    API
                                                  </UncontrolledTooltip>
                                                </span>
                                              </td>
                                              {permissionOrder
                                                .filter(
                                                  (po) =>
                                                    !permissionOnOrgOnly[po]
                                                )
                                                .map((perm) =>
                                                  perm ===
                                                  gqltypes.PermissionType
                                                    .read ? (
                                                    <AggregatedReadView
                                                      key={`${
                                                        subjectPerm.client!.id
                                                      }_${
                                                        suPerm.resourceId
                                                      }_${perm}`}
                                                      tr={tr}
                                                      roles={subjectPerm.roles}
                                                      borderStyle={"solid"}
                                                    />
                                                  ) : (
                                                    <td
                                                      key={`${
                                                        subjectPerm.client!.id
                                                      }_${
                                                        suPerm.resourceId
                                                      }_${perm}`}
                                                      className="text-center"
                                                      style={
                                                        permissionColStyle.td
                                                      }
                                                    >
                                                      {subjectPerm.roles.some(
                                                        (role) =>
                                                          role.roleId === perm
                                                      ) ? (
                                                        <i className="fa fa-check text-dark" />
                                                      ) : (
                                                        ""
                                                      )}
                                                    </td>
                                                  )
                                                )}
                                            </tr>
                                          ) : null;
                                        }
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        );
                      }}
                    </Query>
                  )}
                </div>
              );
          })}
        </ISTContainer>
      </main>
    );
  }

  private toggleSchoolUnitExpanded = (id: string) => {
    this.setState((state) => ({
      expandedSchoolUnits: {
        ...state.expandedSchoolUnits,
        [id]: state.expandedSchoolUnits[id] ? false : true,
      },
    }));
  };
}

interface Response {
  data: DataValue<gqltypes.OrganisationPermissions> &
    QueryResult<gqltypes.OrganisationPermissionsVariables>;
}

export const ORG_PERMISSION_QUERY = gql`
  query OrganisationPermissions($context: Context!) {
    organisation(context: $context) {
      id
      displayName
      skolidOrgId
      permissions {
        resourceId
        displayName
        organisation {
          user {
            id
            source
            customerId
            name
            idp
            connectedUsers {
              id
              source
            }
            skolidOrganization {
              id
              name
              country
            }
          }
          client {
            id
            clientId
            name
          }
          roles {
            roleId
            sources
          }
        }
        schoolUnits {
          resourceId
          schoolUnit {
            id
            organisationCode
            schoolUnitCode
          }
          displayName
          permissions {
            employmentRoles
            displayName
            source
            userId
            client {
              id
              clientId
              name
            }
            roles {
              roleId
              sources
            }
          }
        }
      }
    }
  }
`;

const withOrgPermissions = graphql<
  Props,
  gqltypes.OrganisationPermissions,
  gqltypes.OrganisationPermissionsVariables,
  { data: DataValue<gqltypes.OrganisationPermissions> }
>(ORG_PERMISSION_QUERY, {
  options: (props) => ({
    variables: {
      context: props.adminContext,
    },
    fetchPolicy: "cache-and-network",
  }),
});

export const PermissionsContainer = withTranslation(
  withAdminContext(withOrgPermissions(Permissions))
);

const sortListByConnectedUsers = (props: SchoolUnitPermissionConditional[]) => {
  const sorted: SchoolUnitPermissionConditional[] = [];

  for (let i = 0; i < props.length; i++) {
    let user: gqltypes.OrganisationPermissions_organisation_permissions_organisation_user;

    if (props[i] && !sorted.includes(props[i])) {
      if (props[i].user) {
        user = props[i].user!;
        let potentionalUserMatch: gqltypes.OrganisationPermissions_organisation_permissions_organisation_user;
        sorted.push(props[i]);

        for (let j = 0; j < props.length; j++) {
          if (props[j].user?.connectedUsers?.length) {
            potentionalUserMatch = props[j].user!;
            if (
              potentionalUserMatch.connectedUsers &&
              potentionalUserMatch.connectedUsers?.length &&
              potentionalUserMatch.connectedUsers?.length > 0
            ) {
              for (
                let k = 0;
                k < potentionalUserMatch.connectedUsers?.length;
                k++
              ) {
                if (
                  potentionalUserMatch.connectedUsers[k].id === user.id &&
                  potentionalUserMatch.connectedUsers[k].source !== "educloud"
                ) {
                  //push the connected user into the array
                  sorted.push(props[j]);
                }
              }
            }
          }
        }
      } else {
        //push clients into list
        sorted.push(props[i]);
      }
    }
  }

  return { sorted };
};
