import "unfetch/polyfill";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import {
  ApolloClient,
  defaultDataIdFromObject,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { getAuthToken } from "./App";
import * as actions from "./App/actions";
import introspectionQueryResultData from "./fragmentTypes.json";
import * as gqltypes from "./gqltypes";
import * as sentry from "./sentry";
import { getSettings } from "./settings";
import { store } from "./store";

const settings = getSettings();

const authLink = setContext(async (_, { headers }) => {
  const authToken = await getAuthToken();
  const appState = store.getState().app;

  // return the headers to the context so httpLink can read them
  if (!authToken) {
    throw new Error("Expected user.");
  }
  const languageHeader = appState.userInfoLoaded
    ? { "x-language": appState.uiLanguage }
    : {};
  return {
    headers: {
      ...headers,
      ...languageHeader,
      authorization: authToken ? `Bearer ${authToken}` : null,
      "Accept-Encoding": "gzip",
    },
  };
});

const reloadPagePrompt = " Ladda om sidan och försök igen.";
const severeErrors = {
  loa: {
    title: "Inloggning med för låg säkerhetsnivå",
    message:
      "Tjänsten kräver en högre säkerhetsnivå, logga ut på skolid.se och sedan in på nytt.",
  },
  internal: {
    title: "Oväntat fel uppstod",
    message: "Ett oväntat fel uppstod. " + reloadPagePrompt,
  },
  network: {
    title: "Nätverksfel",
    message: "Ett oväntat nätverksfel uppstod. " + reloadPagePrompt,
  },
};

const errorLink = onError((obj) => {
  // Is network error
  if (obj.networkError) {
    if (obj.response) {
      obj.response.errors = undefined;
    } else {
      store.dispatch(actions.errorSplash(severeErrors.network));

      sentry.captureException(obj.networkError, {
        extra: {
          info: obj,
          operationName: obj.operation.operationName,
          fullScreenSplash: true,
        },
      });
    }
  } else {
    if (
      obj.graphQLErrors &&
      obj.graphQLErrors.every((e) =>
        e.extensions ? e.extensions.code === "ACCESS" : false
      )
    ) {
      // Ignore when errors are only about not having access
      return;
    }

    let fullScreenSplash = false;
    if (obj.graphQLErrors) {
      obj.graphQLErrors.forEach((err) => {
        const code: string | undefined = (err as any).code;
        if (code === undefined) {
          return;
        }
        if (Object.keys(severeErrors).some((severe) => severe === code)) {
          fullScreenSplash = true;
          store.dispatch(
            actions.errorSplash(severeErrors[code as keyof typeof severeErrors])
          );
        }
      });
    }
    sentry.captureMessage("ApolloClient' Error", {
      level: sentry.SeverityLevel.error,
      extra: { error: obj, fullScreenSplash },
    });
  }
});

const possibleTypes: { [key: string]: any } = {};
introspectionQueryResultData.__schema.types.forEach((supertype) => {
  if (supertype.possibleTypes) {
    possibleTypes[supertype.name] = supertype.possibleTypes.map(
      (subtype) => subtype.name
    );
  }
});

const ignoreIdList = [
  "PublicationGroupStudentGroup",
  "TranslateComponent",
  "TranslatePredicateComponent",
  "FormTranslateQuestion",
  "TranslateFormQuestionOptions",
  "TemplateTranslation",
  "TranslationComponentTemplate",
  "SubjectCoSigner",
];

export const client = new ApolloClient({
  link: authLink
    .concat(errorLink)
    .concat(new HttpLink({ uri: settings.apiUrl })),
  cache: new InMemoryCache({
    possibleTypes,
    dataIdFromObject: (obj: any): any => {
      if (ignoreIdList.some((type) => type === obj.__typename)) {
        return null;
      }

      if (obj.__typename === "TranslationForm") {
        const tf = obj as gqltypes.TranslationForm;
        return tf.formId && tf.language
          ? tf.formId + "_" + tf.language
          : defaultDataIdFromObject(obj);
      }

      return defaultDataIdFromObject(obj);
    },
  }),
  // React-apollo uses watchQuery, not query
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
});

export const getAdminContext = () => {
  const context = store.getState().app.context;
  return {
    org: context.org,
    language: context.language,
  };
};
