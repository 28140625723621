import gql from "graphql-tag";
import * as _ from "lodash";
import * as React from "react";
import { QueryResult } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { graphql } from "@apollo/client/react/hoc";
import {
  AdminContext,
  AppState,
  Translation,
  withAdminContext,
  withTranslation,
  withUserPermissions,
} from "../../App/reducer";
import {
  Button,
  Checkbox,
  FormSelect,
  ISTContainer,
  Loading,
} from "../../Common";
import { ClipboardCopyButton } from "../../Common/components/ClipboardCopyButton";
import FormSearchSelect from "../../Common/components/FormSearchSelect";
import { GenericError } from "../../Common/components/GenericError";
import { Time } from "../../Common/components/Time";
import {
  formComponentDataFragment,
  fullFormAnswerFragment,
} from "../../Common/fragments";
import {
  downloadTableAsCsv,
  getCombinedIdCustomerId,
  getPartsFromCombinedIdCustomerId,
  isIdCustomerIdSame,
} from "../../Common/utils";
import * as f from "../../Utils/functional";
import { assertUnreachable } from "../../Utils/typeHelpers";
import * as gqltypes from "../../gqltypes";
import { getComponentPermissionDisplayName } from "../../permissions";
import { SYMBOLS, getSelectedResponses } from "../form";
import {
  allPredicateQuestions,
  allPredicateQuestionsAndComponents,
} from "./CreateForm";
import { PublicationApplicationMetaInfo } from "./PublicationMetaInfo";

export type Extras = "birthDate" | "signers" | "date" | "email";
export type ColumnExtras = Exclude<Extras, "birthDate">;

export interface ExtraColumnData {
  id: Extras;
  default?: boolean;
}

export const getEmails = (
  signatures: {
    signer: {
      name: string | null;
      emails: { value: string; preferredHome: boolean }[] | null;
    } | null;
  }[]
) => {
  return signatures
    .map((s) => {
      if (s.signer) {
        const name = s.signer.name;
        const email =
          s.signer.emails && s.signer.emails.find((e) => e.preferredHome);

        if (email) {
          return '"' + name + '" <' + email.value + ">";
        }

        return "";
      }
    })
    .map((v, i, es) =>
      i + 1 < es.length ? (
        <>
          {v},<br />
        </>
      ) : (
        v
      )
    );
};

export const getExtraColumnLabel = (tr: Translation["tr"], id: Extras) => {
  switch (id) {
    case "signers":
      return tr("orgReportExtraSignersLabel");
    case "date":
      return tr("orgReportExtraDateLabel");
    case "birthDate":
      return tr("orgReportExtraBirthDateLabel");
    case "email":
      return tr("orgReportExtraEmailLabel");
    default:
      assertUnreachable(id);
      return "";
  }
};

export const extraColumns: ExtraColumnData[] = [
  { id: "signers" },
  { id: "date" },
];

const extraInlineInfo: ExtraColumnData[] = [{ id: "birthDate" }];

const PUBLICATIONS_QUERY = gql`
  query publications($id: ID!, $customerId: ID!, $context: Context!) {
    schoolUnit(id: $id, customerId: $customerId, context: $context) {
      id
      customerId
      publications {
        id
        name
        created
        creator {
          id
          name
        }
        validFrom
        validTo
        sendDate
        sent
        lastNotified
        form {
          id
          name
          componentData {
            ...FormComponentData
          }
        }
      }
    }
  }
  ${formComponentDataFragment}
`;

const REPORT_QUERY = gql`
  query publicationReport(
    $id: ID!
    $customerId: ID!
    $publicationId: ID!
    $context: Context!
  ) {
    schoolUnit(id: $id, customerId: $customerId, context: $context) {
      id
      customerId
      displayName
      classes {
        id
        customerId
        displayName
        publicationRecipients(publicationId: $publicationId) {
          user {
            id
            name
            birthDate
          }
          responses(filter: LastValidOrElseLastPartiallySigned) {
            id
            modified
            status
            signRole
            signatures {
              educloudUserId
              educloudUser {
                id
                customerId
                name
              }
              signerId
              signer {
                id
                name
              }
            }
            response {
              ...FullFormAnswer
            }
          }
        }
      }
    }
  }
  ${fullFormAnswerFragment}
`;

export function ReportOptions(props: {
  tr: Translation["tr"];
  permissions: AppState["permissions"];
  publication: gqltypes.publications["schoolUnit"]["publications"][0];
  handleChangeSelected: (id: string, selected: boolean) => void;
  handleChangeExtras: (id: string, selected: boolean) => void;
  setCheckAll?: (id: string, selected: boolean) => void;
  selectedQuestions: { [key: string]: boolean };
  isAllQuestionsChecked?: boolean;
  selectedExtras: { [key: string]: boolean };
  schoolUnitId?: { id: string; customerId: string };
}) {
  const form = props.publication.form;
  const predicateComponents = form.componentData.predicateComponents || [];
  return (
    <div>
      <h4>{props.tr("orgReportShowExtras")}</h4>
      <div className="row mb-content">
        {extraColumns.concat(extraInlineInfo).map((e) => (
          <div key={e.id} className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Checkbox
              id={e.id}
              onChange={props.handleChangeExtras}
              label={getExtraColumnLabel(props.tr, e.id)}
              checked={props.selectedExtras[e.id] === true}
            />
          </div>
        ))}
      </div>
      <h4 className="pt-3">{props.tr("orgReportShowQuestions")}</h4>
      {props.isAllQuestionsChecked !== undefined ? (
        <div className="mb-content">
          <Checkbox
            onChange={props.setCheckAll}
            label={props.tr("reportMarkAllAnswers")}
            checked={props.isAllQuestionsChecked}
            id={"checkAll"}
          />
        </div>
      ) : null}
      {form.componentData.components.map((c) => {
        // const access = canAccessComponentResponse(
        //   props.permissions,
        //   props.schoolUnitId,
        //   c.permission
        // );
        // TODO: Fix access
        const access = true;
        return (
          <div key={c.id} className="mb-content">
            <span>
              <strong>
                {c.title}{" "}
                {!access && (
                  <React.Fragment>
                    &ndash;{" "}
                    {props.tr(
                      "reportMissingAccessError",
                      getComponentPermissionDisplayName(c.permission, props.tr)
                    )}
                  </React.Fragment>
                )}
              </strong>
            </span>

            <div className="row">
              {c.questions.map((question) => {
                const questionLabel = (q: gqltypes.FormQuestion) =>
                  (q.shortName ? `${q.shortName} - ` : "") + q.question;
                return (
                  <React.Fragment key={question.id}>
                    <div
                      key={question.id}
                      className="col-12 col-sm-6 col-md-4 col-lg-3"
                    >
                      <Checkbox
                        disabled={!access}
                        onChange={props.handleChangeSelected}
                        label={questionLabel(question)}
                        checked={props.selectedQuestions[question.id] === true}
                        id={question.id}
                      />
                    </div>

                    {allPredicateQuestions(predicateComponents, question).map(
                      (q) => (
                        <div
                          key={q.id}
                          className="col-12 col-sm-6 col-md-4 col-lg-3"
                        >
                          <Checkbox
                            // disabled={!access}
                            onChange={props.handleChangeSelected}
                            label={questionLabel(q)}
                            checked={props.selectedQuestions[q.id] === true}
                            id={q.id}
                          />
                        </div>
                      )
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

interface Props extends Translation, AdminContext {
  schoolData: gqltypes.schoolUnits & QueryResult;
  permissions: AppState["permissions"];
}
interface State {
  generateReport: boolean;
  selectedSchoolUnit?: { id: string; customerId: string };
  selectedListValue: { value: string; label: string } | null;
  selectedPublication?: string;
  isAllQuestionsChecked: boolean;
  selectedQuestions: { [key: string]: boolean };
  selectedExtras: { [key: string]: boolean };
  hideUnanswered: boolean;
  hidePartiallySigned: boolean;
}

class Reports extends React.Component<Props, State> {
  private reportTable: React.RefObject<HTMLTableElement>;
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedListValue: null,
      generateReport: false,
      selectedQuestions: {},
      isAllQuestionsChecked: false,
      selectedExtras: {},
      hideUnanswered: true,
      hidePartiallySigned: false,
    };

    this.reportTable = React.createRef();
  }

  public render() {
    const { tr } = this.props;
    if (this.props.schoolData.loading) {
      return <Loading />;
    }
    const schoolData = this.props.schoolData;

    const schoolUnits = _.orderBy(
      _.flatten(schoolData.organisation.schoolUnits).filter(
        (su) => su.classes.length
      ),
      "displayName"
    );

    return (
      <div>
        <h1>{tr("reportsTitle")}</h1>
        <p className="col-12 col-md-9 p-0 pb-3">{tr("reportsDescription")}</p>
        <ISTContainer header={tr("reportsHeader")}>
          <div className="p-content">{tr("reportsUsageDescription")}</div>
          {schoolData.loading ? (
            <Loading className="p-content" />
          ) : (
            <div>
              {schoolUnits && schoolUnits.length <= 10 ? (
                <div className="row p-content">
                  {schoolUnits.map((su) => (
                    <div key={su.id + su.customerId} className="col-auto py-3">
                      <Button
                        label={su.displayName}
                        level={
                          this.state.selectedSchoolUnit &&
                          isIdCustomerIdSame(this.state.selectedSchoolUnit, su)
                            ? "primary"
                            : "secondary"
                        }
                        onClick={() => {
                          if (
                            !this.state.selectedSchoolUnit ||
                            !isIdCustomerIdSame(
                              this.state.selectedSchoolUnit,
                              su
                            )
                          ) {
                            this.handleChangeSchoolUnit({
                              value: getCombinedIdCustomerId(su),
                              label: su.displayName,
                            });
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <form className="p-content">
                  <FormSearchSelect
                    aria-label={tr("reportsUsageDescriptionShort")}
                    menuShouldScrollIntoView
                    placeholder={tr("chooseSchoolUnit")}
                    noOptionsMessage={() => tr("noSearchHits")}
                    maxMenuHeight={320}
                    isLoading={!schoolUnits}
                    options={schoolUnits.map((school) => ({
                      value: getCombinedIdCustomerId(school),
                      label: school.displayName,
                    }))}
                    value={this.state.selectedListValue}
                    onChange={(item: any) => {
                      this.handleChangeSchoolUnit(item);
                    }}
                  />
                </form>
              )}
            </div>
          )}
        </ISTContainer>
        {this.state.selectedSchoolUnit && (
          <ISTContainer header={tr("reportsCreateReportHeader")}>
            <Query<gqltypes.publications, gqltypes.publicationsVariables>
              skip={!this.state.selectedSchoolUnit}
              query={PUBLICATIONS_QUERY}
              variables={{
                id: this.state.selectedSchoolUnit.id,
                customerId: this.state.selectedSchoolUnit.customerId,
                context: this.props.adminContext,
              }}
            >
              {({ loading, error, data }) => {
                if (loading || !data) {
                  return <Loading />;
                }
                if (error) {
                  return <GenericError title={tr("genericLoadError")} />;
                }
                const publications = data.schoolUnit.publications;
                const options = publications.map((p) => ({
                  value: p.id,
                  label: p.name,
                }));
                const selectedPublication = this.state.selectedPublication
                  ? publications.find(
                      (p) => p.id === this.state.selectedPublication
                    )
                  : undefined;

                if (
                  Object.keys(this.state.selectedQuestions).length === 0 &&
                  selectedPublication
                ) {
                  let selectedQuestions: { [key: string]: boolean } = {};

                  const components =
                    selectedPublication.form.componentData.components;

                  const questionsIds: string[] = components.flatMap(
                    (component) =>
                      component.questions.map((question) => question.id)
                  );

                  if (
                    selectedPublication.form.componentData.predicateComponents
                  ) {
                    selectedPublication.form.componentData.predicateComponents.forEach(
                      (pc) => {
                        pc.questions.forEach((question) => {
                          questionsIds.push(question.id);
                        });
                      }
                    );
                  }

                  selectedQuestions = _.fromPairs(
                    questionsIds.map((question) => [question, false])
                  ) as {
                    string: boolean;
                  };

                  this.setState({
                    selectedQuestions: selectedQuestions,
                    isAllQuestionsChecked: false,
                  });
                }
                return (
                  <React.Fragment>
                    <div className="p-content">
                      {publications.length === 0 ? (
                        <div className="alert alert-info">
                          {tr("reportsNoAvailablePublications")}
                        </div>
                      ) : (
                        <FormSelect
                          defaultOption={tr("reportsChoosePublication")}
                          value={this.state.selectedPublication || ""}
                          onChange={(e) =>
                            this.setState({
                              selectedPublication: e.currentTarget.value,
                              generateReport: false,
                              selectedQuestions: {},
                            })
                          }
                          options={options}
                          label={tr("publication")}
                        />
                      )}
                    </div>
                    {selectedPublication && (
                      <React.Fragment>
                        <PublicationApplicationMetaInfo
                          publication={selectedPublication}
                          fields={[
                            "created",
                            "creator",
                            "sendDate",
                            "validTo",
                            "validFrom",
                          ]}
                        />
                        <div className="p-content">
                          <ReportOptions
                            tr={tr}
                            schoolUnitId={this.state.selectedSchoolUnit}
                            permissions={this.props.permissions}
                            publication={selectedPublication}
                            setCheckAll={(id, checked) => {
                              this.setCheckAll(checked);
                            }}
                            isAllQuestionsChecked={
                              this.state.isAllQuestionsChecked
                            }
                            selectedQuestions={this.state.selectedQuestions}
                            handleChangeSelected={
                              this.handleChangeSelectedQuestions
                            }
                            selectedExtras={this.state.selectedExtras}
                            handleChangeExtras={this.handleChangeExtras}
                          />
                          <Checkbox
                            containerClassName="pb-content"
                            id="hideUnanswered"
                            label={tr("reportsHideNotAnswered")}
                            checked={this.state.hideUnanswered}
                            onChange={(id, checked) => {
                              this.setState({ hideUnanswered: checked });
                            }}
                          />
                          <Checkbox
                            containerClassName="pb-content"
                            id="hidePartiallySigned"
                            label={tr("reportsHidePartiallySigned")}
                            checked={this.state.hidePartiallySigned}
                            onChange={(id, checked) => {
                              this.setState({ hidePartiallySigned: checked });
                            }}
                          />
                          <Button
                            label={tr("create")}
                            disabled={this.state.generateReport}
                            onClick={() =>
                              this.setState({ generateReport: true })
                            }
                          />
                        </div>
                        {this.state.generateReport &&
                          this.renderReportSection(
                            data.schoolUnit,
                            selectedPublication
                          )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              }}
            </Query>
          </ISTContainer>
        )}
      </div>
    );
  }

  private renderReportSection(
    schoolUnit: { id: string; customerId: string },
    publication: gqltypes.publications["schoolUnit"]["publications"][0]
  ) {
    const { tr } = this.props;
    const questionIds = Object.keys(this.state.selectedQuestions);
    const selectedQuestions = questionIds.filter(
      (id) => this.state.selectedQuestions[id] === true
    );
    const predicateComponents =
      publication.form.componentData.predicateComponents || [];
    const components = publication.form.componentData.components;
    /*      .map(c => {
        return {
          id: c.id,
          title: c.title,
          selectedQuestions: f
            .flattenAll(
              c.questions.map(q => [
                q,
                allPredicateQuestions(predicateComponents, q)
              ])
            )
            .filter(q => contains(selectedQuestions, q.id))
        };
      })
      .filter(c => c.selectedQuestions.length > 0);*/

    const questions = f
      .flattenAll(
        components.map((c) =>
          c.questions.map((q) => {
            const predQuestions = allPredicateQuestionsAndComponents(
              predicateComponents,
              q
            ).map(({ question, predicateComponent: pc }) => ({
              component: c,
              question,
              pc,
            }));
            return [{ component: c, question: q }, predQuestions];
          })
        )
      )
      .filter(({ question }) => f.contains(selectedQuestions, question.id));

    return (
      <Query<gqltypes.publicationReport, gqltypes.publicationReportVariables>
        query={REPORT_QUERY}
        variables={{
          publicationId: publication.id,
          id: schoolUnit.id,
          customerId: schoolUnit.customerId,
          context: this.props.adminContext,
        }}
      >
        {({ loading, data }) => {
          if (loading) {
            return <Loading />;
          }
          if (!data) {
            return <span>{tr("reportsNoData")}</span>;
          }
          const people = _.orderBy(
            _.flatten(
              data.schoolUnit.classes.map((c) => {
                return c.publicationRecipients.map((r) => {
                  return {
                    response: r.responses.length ? r.responses[0] : null,
                    user: r.user,
                    group: c,
                  };
                });
              })
            ),
            ["group.displayName", "user.name"]
          ) as {
            group: gqltypes.publicationReport_schoolUnit_classes;
            response: gqltypes.publicationReport_schoolUnit_classes_publicationRecipients_responses;
            user: gqltypes.publicationReport_schoolUnit_classes_publicationRecipients_user | null;
          }[];

          const numQuestionColumns = Object.values(
            this.state.selectedQuestions
          ).filter((v) => v === true).length;

          return (
            <React.Fragment>
              <div className="p-content">
                {people.length === 0 ? (
                  <div className="alert alert-info">
                    {tr("reportsNoMatchingAnswers")}
                  </div>
                ) : (
                  <div>
                    <Button
                      label={tr("downloadReport")}
                      onClick={() =>
                        downloadTableAsCsv(
                          "report-table",
                          `${publication.name}_${data.schoolUnit.displayName}_export`
                        )
                      }
                    />{" "}
                    <ClipboardCopyButton
                      getElementToCopy={() => this.reportTable.current}
                      tooltipText={tr("copyToClipboardTooltip")}
                    />
                    <div className="mt-content">
                      <strong>{tr("reportsStatusTitle")}</strong>
                      <div>
                        {tr(
                          "reportsPartiallySignedDescription",
                          SYMBOLS.PARTIALLY_SIGNED
                        )}
                      </div>
                      <div>
                        {tr(
                          "reportsAdminSignedDescription",
                          SYMBOLS.ADMIN_ANSWER
                        )}
                      </div>
                      <div>
                        {tr("reportUnansweredDescription", SYMBOLS.UNANSWERED)}
                      </div>
                      <div>
                        {tr("reportRedactedDescription", SYMBOLS.REDACTED)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {people.length > 0 && (
                <div style={{ overflowX: "auto" }}>
                  <table
                    id="report-table"
                    className="table table-sticky-header"
                    ref={this.reportTable}
                  >
                    <thead>
                      <tr>
                        <th>{tr("reportsColumnStatus")}</th>
                        <th>{tr("reportsColumnName")}</th>
                        <th>{tr("reportsColumnClass")}</th>
                        {extraColumns.map((e) => {
                          if (!this.state.selectedExtras[e.id]) {
                            return null;
                          }
                          return (
                            <th key={e.id}>
                              {getExtraColumnLabel(this.props.tr, e.id)}
                            </th>
                          );
                        })}
                        {questions.map(({ component: c, question: q }, num) => (
                          <th key={q.id}>{`${c.title} ${num + 1}: ${_.defaultTo(
                            q.shortName,
                            q.question
                          )}`}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {people.map((p, i) => {
                        const { user } = p;
                        const {
                          id: userId = `noUserId_${i}`,
                          name: userName = <i>{tr("failedToFetchUser")}</i>,
                        } = user || {};
                        if (
                          this.state.hideUnanswered &&
                          (!p.response || !p.response.response)
                        ) {
                          return null;
                        }
                        if (
                          this.state.hidePartiallySigned &&
                          p.response &&
                          p.response.status ===
                            gqltypes.PublicationResponseStatus.partially_signed
                        ) {
                          return null;
                        }

                        const signatureNames =
                          p.response && p.response.signatures
                            ? p.response.signatures.map(
                                (
                                  signature: gqltypes.publicationReport_schoolUnit_classes_publicationRecipients_responses_signatures
                                ) => {
                                  const u = signature.educloudUser
                                    ? signature.educloudUser
                                    : signature.signer;

                                  return u ? u.name || "?" : "?";
                                }
                              )
                            : [];

                        const extraColumData: {
                          [id in ColumnExtras]: React.ReactNode;
                        } = p.response
                          ? {
                              signers: signatureNames.join(", "),
                              date: (
                                <Time
                                  date={p.response.modified}
                                  format="date"
                                />
                              ),
                              email: (
                                <>
                                  {/*p.response.signatures && getEmails(p.response.signatures)*/}
                                </>
                              ),
                            }
                          : {
                              signers: SYMBOLS.UNANSWERED,
                              date: SYMBOLS.UNANSWERED,
                              email: SYMBOLS.UNANSWERED,
                            };

                        const birthDate =
                          user && user.birthDate ? (
                            <Time date={user.birthDate} />
                          ) : null;

                        return (
                          <tr key={userId + p.group.id}>
                            <td>
                              {p.response
                                ? p.response.status !==
                                  gqltypes.PublicationResponseStatus.valid
                                  ? SYMBOLS.PARTIALLY_SIGNED
                                  : p.response.signRole === "admin"
                                  ? SYMBOLS.ADMIN_ANSWER
                                  : null
                                : SYMBOLS.UNANSWERED}
                            </td>
                            <td>
                              {userName}
                              {this.state.selectedExtras.birthDate ? (
                                <span> ({birthDate})</span>
                              ) : null}
                            </td>
                            <td>{p.group.displayName}</td>
                            {extraColumns.map((e) => {
                              if (!this.state.selectedExtras[e.id]) {
                                return null;
                              }
                              return (
                                <td key={e.id}>
                                  {extraColumData[e.id as ColumnExtras]}
                                </td>
                              );
                            })}
                            {!p.response || !p.response.response
                              ? _.range(0, numQuestionColumns).map((num) => (
                                  <td key={"no-response-" + num}>
                                    {SYMBOLS.UNANSWERED}
                                  </td>
                                ))
                              : getSelectedResponses(
                                  this.props.tr,
                                  components,
                                  questions,
                                  p.response.response,
                                  p.response.id
                                ).map(({ key, value, redacted }) => (
                                  <td key={key}>
                                    {redacted ? SYMBOLS.REDACTED : value}
                                  </td>
                                ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </React.Fragment>
          );
        }}
      </Query>
    );
  }

  private handleChangeSchoolUnit = (selected: {
    value: string; // as combinedIdCustomerId
    label: string;
  }): void => {
    this.setState({
      selectedSchoolUnit: getPartsFromCombinedIdCustomerId(selected.value),
      selectedListValue: selected,
      generateReport: false,
      selectedPublication: undefined,
      isAllQuestionsChecked: false,
      selectedQuestions: {},
    });
  };

  private setCheckAll = (checked: boolean): void => {
    const updatedQuestions = this.state.selectedQuestions;

    if (
      this.state.selectedQuestions &&
      Object.keys(this.state.selectedQuestions).length > 0
    ) {
      Object.keys(updatedQuestions).forEach((question) => {
        updatedQuestions[question] = checked;
      });
    }

    this.setState({
      isAllQuestionsChecked: checked,
      selectedQuestions: updatedQuestions,
    });
  };

  private checkIfAllColumnsChecked = (): void => {
    if (
      Object.keys(this.state.selectedQuestions).every((key) => {
        return this.state.selectedQuestions[key];
      })
    ) {
      this.setState({
        isAllQuestionsChecked: true,
      });
    } else {
      this.setState({
        isAllQuestionsChecked: false,
      });
    }
  };

  private handleChangeSelectedQuestions = (
    id: string,
    selected: boolean
  ): void => {
    this.setState(
      (state) => {
        return {
          selectedQuestions: { ...state.selectedQuestions, [id]: selected },
        };
      },
      () => {
        this.checkIfAllColumnsChecked();
      }
    );
  };

  private handleChangeExtras = (id: Extras, selected: boolean): void => {
    this.setState((state) => {
      return {
        selectedExtras: { ...state.selectedExtras, [id]: selected },
      };
    });
  };
}

const schoolsQuery = gql`
  query schoolUnits($context: Context!) {
    organisation(context: $context) {
      id
      schoolUnits(filter: { permissionType: read }) {
        id
        customerId
        displayName
        classes {
          id
          customerId
        }
      }
    }
  }
`;

const withSchoolsData = graphql<Props, gqltypes.schoolUnits>(schoolsQuery, {
  name: "schoolData",
  options: (props) => ({
    variables: {
      context: props.adminContext,
    },
  }),
});

export const ReportsContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withUserPermissions,
  withSchoolsData
)(Reports);
