import gql from "graphql-tag";
import * as _ from "lodash";
import * as React from "react";
import { QueryResult } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { Query } from "@apollo/client/react/components";
import {
  AdminContext,
  AppState,
  Translation,
  withAdminContext,
  withTranslation,
  withUserPermissions,
} from "../../App/reducer";
import {
  Button,
  Checkbox,
  FormSelect,
  ISTContainer,
  Loading,
} from "../../Common";
import { ClipboardCopyButton } from "../../Common/components/ClipboardCopyButton";
import { GenericError } from "../../Common/components/GenericError";
import { Time } from "../../Common/components/Time";
import {
  formComponentDataFragment,
  fullFormAnswerFragment,
} from "../../Common/fragments";
import { downloadTableAsCsv } from "../../Common/utils";
import { contains } from "../../Utils/functional";
import * as f from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";
import { SYMBOLS, getSelectedResponses } from "../form";
import { allPredicateQuestionsAndComponents } from "./CreateForm";
import { PublicationApplicationMetaInfo } from "./PublicationMetaInfo";
import {
  ColumnExtras,
  Extras,
  ReportOptions,
  extraColumns,
  getExtraColumnLabel,
} from "./Reports";

const REPORT_QUERY = gql`
  query OrgPublicationReport($publicationId: ID!) {
    publication(id: $publicationId) {
      id
      name
      recipients {
        groups {
          edges {
            id
            displayName
          }
        }
        users {
          edges {
            id
            responses(filter: LastValidOrElseLastPartiallySigned) {
              id
              modified
              status
              signRole
              signatures {
                educloudUserId
                educloudUser {
                  id
                  name
                }
                signerId
                signer {
                  id
                  name
                }
              }
              response {
                ...FullFormAnswer
              }
            }
            user {
              id
              name
              birthDate
            }
            groupIds
          }
        }
      }
    }
  }
  ${fullFormAnswerFragment}
`;

interface Props extends Translation, AdminContext {
  publicationsData: gqltypes.OrgReportPublications & QueryResult;
  permissions: AppState["permissions"];
}

interface State {
  generateReport: boolean;
  selectedListValue: { value: string; label: string } | null;
  selectedPublication?: string;
  selectedQuestions: { [key: string]: boolean };
  selectedExtras: { [key: string]: boolean };
  isAllQuestionsChecked?: boolean;
  hideUnanswered: boolean;
  hidePartiallySigned: boolean;
}

class OrgReports extends React.Component<Props, State> {
  private reportTable: React.RefObject<HTMLTableElement>;
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedListValue: null,
      generateReport: false,
      selectedQuestions: {},
      selectedExtras: {},
      hideUnanswered: true,
      hidePartiallySigned: false,
    };

    this.reportTable = React.createRef();
  }

  public render() {
    const { tr, publicationsData } = this.props;
    if (publicationsData.error) {
      return <GenericError title={tr("failedToFetchData")} />;
    }

    if (publicationsData.loading || !publicationsData.organisation) {
      return <Loading />;
    }

    const publications = publicationsData.organisation.publications;
    const options = publications.map((p) => ({
      value: p.id,
      label: p.name,
    }));

    const selectedPublication = this.state.selectedPublication
      ? publications.find((p) => p.id === this.state.selectedPublication)
      : undefined;

    if (
      Object.keys(this.state.selectedQuestions).length === 0 &&
      selectedPublication
    ) {
      let selectedQuestions: { [key: string]: boolean } = {};

      const components = selectedPublication.form.componentData.components;

      const questionsIds: string[] = components.flatMap((component) =>
        component.questions.map((question) => question.id)
      );

      if (selectedPublication.form.componentData.predicateComponents) {
        selectedPublication.form.componentData.predicateComponents.forEach(
          (pc) => {
            pc.questions.forEach((question) => {
              questionsIds.push(question.id);
            });
          }
        );
      }

      selectedQuestions = _.fromPairs(
        questionsIds.map((question) => [question, false])
      ) as {
        string: boolean;
      };

      this.setState({
        selectedQuestions: selectedQuestions,
        isAllQuestionsChecked: false,
      });
    }

    return (
      <div>
        <h1>{tr("orgReportsTitle")}</h1>
        <p className="col-12 col-md-9 p-0 pb-3">
          {tr("orgReportsDescription")}
        </p>
        <ISTContainer header={tr("orgReportsHeader")}>
          <React.Fragment>
            <div className="p-content">
              {publications.length === 0 ? (
                <div className="alert alert-info">
                  {tr("orgReportsNoAvailablePublications")}
                </div>
              ) : (
                <FormSelect
                  defaultOption={tr("reportsChoosePublication")}
                  value={this.state.selectedPublication || ""}
                  onChange={(e) =>
                    this.setState({
                      selectedPublication: e.currentTarget.value,
                      generateReport: false,
                      selectedQuestions: {},
                    })
                  }
                  options={options}
                  label={tr("publication")}
                />
              )}
            </div>
            {selectedPublication && (
              <React.Fragment>
                <PublicationApplicationMetaInfo
                  publication={selectedPublication}
                  fields={[
                    "created",
                    "creator",
                    "sendDate",
                    "validTo",
                    "validFrom",
                  ]}
                />
                <div className="p-content">
                  <ReportOptions
                    tr={tr}
                    // schoolUnitId={"}
                    permissions={this.props.permissions}
                    publication={selectedPublication}
                    selectedQuestions={this.state.selectedQuestions}
                    isAllQuestionsChecked={this.state.isAllQuestionsChecked}
                    setCheckAll={(id, checked) => this.setCheckAll(checked)}
                    selectedExtras={this.state.selectedExtras}
                    handleChangeSelected={this.handleChangeSelectedQuestions}
                    handleChangeExtras={this.handleChangeExtras}
                  />
                  <Checkbox
                    containerClassName="pb-content"
                    id="hideUnanswered"
                    label={tr("reportsHideNotAnswered")}
                    checked={this.state.hideUnanswered}
                    onChange={(id, checked) => {
                      this.setState({ hideUnanswered: checked });
                    }}
                  />
                  <Checkbox
                    containerClassName="pb-content"
                    id="hidePartiallySigned"
                    label={tr("reportsHidePartiallySigned")}
                    checked={this.state.hidePartiallySigned}
                    onChange={(id, checked) => {
                      this.setState({ hidePartiallySigned: checked });
                    }}
                  />
                  {this.state.generateReport ? null : (
                    <Button
                      label={tr("create")}
                      disabled={this.state.generateReport}
                      onClick={() => this.setState({ generateReport: true })}
                    />
                  )}
                </div>
                {this.state.generateReport &&
                  this.renderReportSection(selectedPublication)}
              </React.Fragment>
            )}
          </React.Fragment>
        </ISTContainer>
      </div>
    );
  }

  private renderReportSection(
    publication: gqltypes.publications["schoolUnit"]["publications"][0]
  ) {
    const { tr } = this.props;
    const questionIds = Object.keys(this.state.selectedQuestions);
    const selectedQuestions = questionIds.filter(
      (id) => this.state.selectedQuestions[id] === true
    );
    const selectedExtras = Object.keys(this.state.selectedExtras).filter(
      (s) => this.state.selectedExtras[s] === true
    );
    const components = publication.form.componentData.components;
    const predicateComponents =
      publication.form.componentData.predicateComponents || [];

    const questions = f
      .flattenAll(
        components.map((c) =>
          c.questions.map((q) => {
            const predQuestions = allPredicateQuestionsAndComponents(
              predicateComponents,
              q
            ).map(({ question: q2, predicateComponent: pc }) => ({
              component: c,
              question: q2,
              pc,
            }));
            return [{ component: c, question: q }, predQuestions];
          })
        )
      )
      .filter(({ question }) => contains(selectedQuestions, question.id));

    return (
      <Query<
        gqltypes.OrgPublicationReport,
        gqltypes.OrgPublicationReportVariables
      >
        query={REPORT_QUERY}
        variables={{
          publicationId: publication.id,
        }}
      >
        {({ loading, data }) => {
          if (loading) {
            return (
              <Loading message={this.props.tr("orgReportsLoadingMessage")} />
            );
          }
          if (!data) {
            return <span>{this.props.tr("errorFetchingData")}</span>;
          }
          const groupNameMap = data.publication.recipients.groups.edges.reduce(
            (out, item) => {
              out[item.id] = item;
              return out;
            },
            {} as { [id: string]: { id: string; displayName: string } }
          );

          const rowsUnsorted: (gqltypes.OrgPublicationReport_publication_recipients_users_edges & {
            group: { id: string; displayName: string };
          })[] = [];
          data.publication.recipients.users.edges.forEach((u) => {
            for (const group of u.groupIds) {
              rowsUnsorted.push({ ...u, group: groupNameMap[group] });
            }
          });

          const rows = _.orderBy(rowsUnsorted, [
            "group.displayName",
            "user.name",
          ]) as (gqltypes.OrgPublicationReport_publication_recipients_users_edges & {
            group: { id: string; displayName: string };
          })[];

          const numQuestionColumns = Object.values(
            this.state.selectedQuestions
          ).filter((v) => v === true).length;

          return (
            <React.Fragment>
              <div className="p-content">
                {rows.length === 0 ? (
                  <div className="alert alert-info">
                    {this.props.tr("orgReportsNoAnswersFound")}
                  </div>
                ) : (
                  <div>
                    <Button
                      label={tr("downloadReport")}
                      onClick={() =>
                        downloadTableAsCsv(
                          "report-table",
                          `${data.publication.name}_export`
                        )
                      }
                    />{" "}
                    <ClipboardCopyButton
                      getElementToCopy={() => this.reportTable.current}
                      tooltipText={this.props.tr("copyToClipboardTooltip")}
                    />
                    <div className="mt-content">
                      <strong>{tr("reportsStatusTitle")}</strong>
                      <div>
                        {tr(
                          "reportsPartiallySignedDescription",
                          SYMBOLS.PARTIALLY_SIGNED
                        )}
                      </div>
                      <div>
                        {tr(
                          "reportsAdminSignedDescription",
                          SYMBOLS.ADMIN_ANSWER
                        )}
                      </div>
                      <div>
                        {tr("reportUnansweredDescription", SYMBOLS.UNANSWERED)}
                      </div>
                      <div>
                        {tr("reportRedactedDescription", SYMBOLS.REDACTED)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {rows.length > 0 && (
                <div style={{ overflowX: "auto" }}>
                  <table
                    id="report-table"
                    className="table table-sticky-header"
                    ref={this.reportTable}
                  >
                    <thead>
                      <tr>
                        <th>{tr("reportsColumnStatus")}</th>
                        <th>{this.props.tr("reportsColumnName")}</th>
                        <th>{this.props.tr("reportsColumnGroup")}</th>
                        {extraColumns.map((e) => {
                          if (!this.state.selectedExtras[e.id]) {
                            return null;
                          }
                          return (
                            <th key={e.id}>
                              {getExtraColumnLabel(this.props.tr, e.id)}
                            </th>
                          );
                        })}
                        {questions.map(({ component: c, question: q }, num) => (
                          <th key={q.id}>{`${c.title} ${num + 1}: ${_.defaultTo(
                            q.shortName,
                            q.question
                          )}`}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row, i) => {
                        const { user } = row;
                        const {
                          name: userName = (
                            <i>{this.props.tr("failedToFetchUser")}</i>
                          ),
                        } = user || {};
                        const responseForm = row.responses.length
                          ? row.responses[0]
                          : null;
                        if (
                          this.state.hideUnanswered &&
                          (!responseForm || !responseForm.response)
                        ) {
                          return null;
                        }
                        if (
                          this.state.hidePartiallySigned &&
                          responseForm &&
                          responseForm.status ===
                            gqltypes.PublicationResponseStatus.partially_signed
                        ) {
                          return null;
                        }

                        const signatureNames =
                          responseForm && responseForm.signatures
                            ? responseForm.signatures.map((signature) => {
                                const u = signature.educloudUser
                                  ? signature.educloudUser
                                  : signature.signer;
                                return u ? u.name || "?" : "?";
                              })
                            : [];

                        const extraColumData: {
                          [id in ColumnExtras]: React.ReactNode;
                        } = responseForm
                          ? {
                              signers: signatureNames.join(", "),
                              date: (
                                <Time
                                  date={responseForm.modified}
                                  format="date"
                                />
                              ),
                              email: (
                                <>
                                  {/*responseForm.signatures &&
                                    getEmails(responseForm.signatures)*/}
                                </>
                              ),
                            }
                          : {
                              signers: SYMBOLS.UNANSWERED,
                              date: SYMBOLS.UNANSWERED,
                              email: SYMBOLS.UNANSWERED,
                            };

                        const birthDate =
                          user && user.birthDate ? (
                            <Time date={user.birthDate} />
                          ) : null;
                        return (
                          <tr key={i}>
                            <td>
                              {responseForm
                                ? responseForm.status !==
                                  gqltypes.PublicationResponseStatus.valid
                                  ? SYMBOLS.PARTIALLY_SIGNED
                                  : responseForm.signRole === "admin"
                                  ? SYMBOLS.ADMIN_ANSWER
                                  : null
                                : SYMBOLS.UNANSWERED}
                            </td>
                            <td>
                              {userName}
                              {this.state.selectedExtras.birthDate ? (
                                <span> ({birthDate})</span>
                              ) : null}
                            </td>
                            <td>{row.group.displayName}</td>
                            {extraColumns.map((e) => {
                              if (!this.state.selectedExtras[e.id]) {
                                return null;
                              }
                              return (
                                <td key={e.id}>
                                  {extraColumData[e.id as ColumnExtras]}
                                </td>
                              );
                            })}
                            {!responseForm || !responseForm.response ? (
                              _.range(0, numQuestionColumns).map((num) => (
                                <td key={"no-response-" + num}>
                                  {SYMBOLS.UNANSWERED}
                                </td>
                              ))
                            ) : (
                              <React.Fragment>
                                {getSelectedResponses(
                                  this.props.tr,
                                  components,
                                  questions,
                                  responseForm.response,
                                  responseForm.id
                                ).map(({ key, value, redacted }) => (
                                  <td key={key}>
                                    {redacted ? SYMBOLS.REDACTED : value}
                                  </td>
                                ))}
                              </React.Fragment>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </React.Fragment>
          );
        }}
      </Query>
    );
  }

  private setCheckAll = (checked: boolean): void => {
    const updatedQuestions = this.state.selectedQuestions;

    if (
      this.state.selectedQuestions &&
      Object.keys(this.state.selectedQuestions).length > 0
    ) {
      Object.keys(updatedQuestions).forEach((question) => {
        updatedQuestions[question] = checked;
      });
    }

    this.setState({
      isAllQuestionsChecked: checked,
      selectedQuestions: updatedQuestions,
    });
  };

  private checkIfAllColumnsChecked = (): void => {
    if (
      Object.keys(this.state.selectedQuestions).every((key) => {
        return this.state.selectedQuestions[key];
      })
    ) {
      this.setState({
        isAllQuestionsChecked: true,
      });
    } else {
      this.setState({
        isAllQuestionsChecked: false,
      });
    }
  };

  private handleChangeSelectedQuestions = (
    id: string,
    selected: boolean
  ): void => {
    this.setState(
      (state) => {
        return {
          selectedQuestions: { ...state.selectedQuestions, [id]: selected },
        };
      },
      () => {
        this.checkIfAllColumnsChecked();
      }
    );
  };

  private handleChangeExtras = (id: Extras, selected: boolean) => {
    this.setState((state) => {
      return {
        selectedExtras: { ...state.selectedExtras, [id]: selected },
      };
    });
  };
}

const publicationsQuery = gql`
  query OrgReportPublications($context: Context!) {
    organisation(context: $context) {
      id
      displayName
      publications {
        id
        name
        created
        creator {
          id
          name
        }
        validFrom
        validTo
        sendDate
        sent
        lastNotified
        form {
          id
          name
          componentData {
            ...FormComponentData
          }
        }
      }
    }
  }
  ${formComponentDataFragment}
`;

const withPublicationsData = graphql<Props, gqltypes.OrgReportPublications>(
  publicationsQuery,
  {
    name: "publicationsData",
    options: (props) => ({
      variables: {
        context: props.adminContext,
      },
    }),
  }
);

export const OrgReportsContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withUserPermissions,
  withPublicationsData
)(OrgReports);
